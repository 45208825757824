import { useEffect, useState } from "react";
import Logo from "../../assests/images/login/logo.png";
import "./style.scss";

const NoInternetConnection = (props) => {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    setIsOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setIsOnline(true);
  });

  window.addEventListener("offline", () => {
    setIsOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="error-bg">
          <img src={Logo} alt="logo" />
        <p className="mb-4 text-white">
          No Internet connection found. Please check your connection and try
          again
        </p>
        {isOnline ? (
          <a href="/" className="btn btn-primary">
            Login
          </a>
        ) : (
          <a href="/#" className="btn btn-primary ">
            Refresh
          </a>
        )}
      </div>
    );
  }
};
export default NoInternetConnection;
