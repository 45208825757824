import apiURL from "../constants/apiURL";
import { apiService } from "./api.service";


const painAssessmentJson = {
  pain_assessment: {
    leftAnkle: {
      frequency: 0,
      intensity: 0,
    },
    rightAnkle: {
      frequency: 0,
      intensity: 0,
    },
    leftElbow: {
      frequency: 0,
      intensity: 0,
    },
    rightElbow: {
      frequency: 0,
      intensity: 0,
    },
    leftFoot: {
      frequency: 0,
      intensity: 0,
    },
    leftHeel: {
      frequency: 0,
      intensity: 0,
    },
    rightFoot: {
      frequency: 0,
      intensity: 0,
    },
    rightHeel: {
      frequency: 0,
      intensity: 0,
    },
    leftJaw: {
      frequency: 0,
      intensity: 0,
    },
    leftHead: {
      frequency: 0,
      intensity: 0,
    },
    rightJaw: {
      frequency: 0,
      intensity: 0,
    },
    rightHead: {
      frequency: 0,
      intensity: 0,
    },
    leftHip: {
      frequency: 0,
      intensity: 0,
    },
    rightHip: {
      frequency: 0,
      intensity: 0,
    },
    leftKnee: {
      frequency: 0,
      intensity: 0,
    },
    rightKnee: {
      frequency: 0,
      intensity: 0,
    },
    leftNeck: {
      frequency: 0,
      intensity: 0,
    },
    rightNeck: {
      frequency: 0,
      intensity: 0,
    },
    leftShoulder: {
      frequency: 0,
      intensity: 0,
    },
    rightShoulder: {
      frequency: 0,
      intensity: 0,
    },
    leftWrist: {
      frequency: 0,
      intensity: 0,
    },
    rightWrist: {
      frequency: 0,
      intensity: 0,
    },
    leftLowerBack: {
      frequency: 0,
      intensity: 0,
    },
    rightLowerBack: {
      frequency: 0,
      intensity: 0,
    },
  },
};


function jsonToFormData(json) {
  const formData = {};

  function addKeys(prefix, obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const formKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === "object" && !Array.isArray(value)) {
          addKeys(formKey, value);
        } else {
          formData[formKey] = value;
        }
      }
    }
  }

  addKeys("", json);
  return formData;
}

const formDataString = jsonToFormData(painAssessmentJson);

async function postPainAssessment(id, _) {
  return apiService.postForm(apiURL.PAIN_ASSESSMENT + id + "/", formDataString);
}


async function postImageUpload(id, formData) {
  return apiService.postForm(apiURL.IMAGE_UPLOAD + id + "/", formData);
}

async function postPainAssessmentOld(id, pain_data = null) {
  if(!pain_data) {
    pain_data = jsonToFormData(painAssessmentJson);
  }
  const pain_data_string = jsonToFormData(pain_data);
  return apiService.postForm(apiURL.PAIN_ASSESSMENT_OLD + id + "/", pain_data_string);
}


async function postImageUploadOld(id, formData) {
  return apiService.postForm(apiURL.IMAGE_UPLOAD_OLD + id + "/", formData);
}

async function getScanDetails(id) {
  return apiService.get(`${apiURL.SCANS_DETAIL}${id}/` );
}

async function getPainAssessment(id) {
  return apiService.get(`${apiURL.PAIN_ASSESSMENT}${id}/`)
}


export const compareService = {
  postPainAssessment,
  postImageUpload,
  getScanDetails,
  postPainAssessmentOld,
  postImageUploadOld,
  getPainAssessment
};