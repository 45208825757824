import { v4 as uuid } from "uuid";
import { Table, InputGroup, Form } from "react-bootstrap";
import {formatDate,formatDateToCustomFormatWithOffset} from "../../utils/helperFunction"
import "./style.scss";

function Tablemy({
  data,
  patientToExport,
  handleSwitchToggle,
  handleEditPatient,
  handleCheckboxChange,
  sendtoAssessmentPage,
  adminUser,
}) {

 

  return (
    <div>
      <Table
        responsive
        className="table-stripednew Patients-table"
        variant="dark"
      >
        <thead>
          <tr>
            <th>
              <InputGroup className="mb-3">
                <InputGroup.Checkbox
                  aria-label="Checkbox for following text input"
                  checked={
                    patientToExport.length === data.length && data.length > 0
                  }
                  onChange={() => handleCheckboxChange("All", null)}
                />
              </InputGroup>
            </th>
            <th> Patient ID</th>
            <th> Patient Name</th>
            <th> Align Score</th>
            <th> No of assessments</th>
            <th> Last Doctor’s Appointment</th>
            <th> Last Self Scan</th>
            <th> Next Scan</th>
            <th> Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((patient) => (
            <tr key={uuid()}>
              <td>
                <InputGroup className="mb-3">
                  <InputGroup.Checkbox
                    aria-label="Checkbox for following text input"
                    checked={patientToExport.includes(patient?.id)}
                    onChange={() => handleCheckboxChange("", patient?.id)}
                  />
                </InputGroup>
              </td>
              <td>{patient?.mrn}</td>
              <td>
                {adminUser ? (
                  <span className="name-admin-user">
                    {patient?.patient_name}
                  </span>

                ) : (
                  <span className="name-text" onClick={() => handleEditPatient(patient?.id)}>
                    {patient?.patient_name}
                  </span>
                )}
              </td>
              <td>
                {patient?.posture_score ? `${patient?.posture_score}%` : "-"}
              </td>
              <td>
                {adminUser ? (
                  <span className="name-admin-user">{patient?.assessment_count}</span>
                  
                ) : (
                  <span className="name-text" onClick={() => sendtoAssessmentPage(patient.id)}>
                    {patient?.assessment_count}
                  </span>
                )}
              </td>
              <td>{patient?.last_doctors_appointment ? formatDate(patient?.last_doctors_appointment) : "No Data"}</td>
              <td>{patient?.last_self_scan ? formatDateToCustomFormatWithOffset(patient?.last_self_scan) : "No Data"}</td>
              <td>{patient?.next_scan ? formatDate(patient?.next_scan) : "No Data"}</td>
              <td>
                <div>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      checked={patient?.is_enabled}
                      onChange={() => handleSwitchToggle(patient)}
                    />
                  </Form>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Tablemy;
