import PaymentComp from "../../../../components/payment";
import TabsWithNavigation from "../../../../components/tabsWithNavigation";
import { editDoctorTabs } from "../../../../constants/doctor.constants";

function EditDoctorPayment({isProfileEdit=false}) {
  return (
    <>
      <div className="Patients_section Organization-section AddOrganisationProfile Add_Organisation_Professional">
        <TabsWithNavigation tabs={editDoctorTabs} heading={isProfileEdit ? "Edit Profile" : "Edit Doctor"}  isProfileEdit={isProfileEdit} />
        <PaymentComp />
      </div>
    </>
  );
}
export default EditDoctorPayment;
