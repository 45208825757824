import { useContext, useEffect, useState } from "react";
// import EditOrganisationProfile from "../organisation/edit/profileDetails";
import EditOrganisationProfessional from "../../organisation/edit/professionalDetails";
import EditClinicProfessional from "../../clinic/edit/professionalDetails";
import { roles } from "../../../constants/common.constants"
import { Store } from "../../../store/Store"
import EditDoctorProfessional from "../../doctor/edit/professionalDetails";

function EditProfessionalDetails() {
  const { state, dispatch } = useContext(Store);
  const { userInfo } = state;

  return (
   <>
     {userInfo.user_type === roles.organization && (
        <EditOrganisationProfessional isProfileEdit={true} />
      )}
      {userInfo.user_type === roles.clinic && <EditClinicProfessional isProfileEdit={true}/>}

      {userInfo.user_type === roles.doctor && <EditDoctorProfessional isProfileEdit={true}/>}
  
   </>
  );
}
export default EditProfessionalDetails;