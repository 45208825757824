import CompareComp from "../../components/compare";
function Compare() {
  return (
    <>
      <div className="Patients_section Organization-section AddOrganisationProfile">
        <CompareComp />
      </div>
    </>
  );
}
export default Compare;
