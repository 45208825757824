import axios from "axios";
import { toast } from "react-toastify";
import { storageService } from "./storage.service";
import { STORAGE_KEYS } from "../constants/common.constants";
import URL from "../constants/routesURL";

const agent = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? "",
  withCredentials: false,
});
const responseBody = (response) => response?.data;

let isRefreshing = false; // Flag to track if refresh token is in progress
let refreshSubscribers = []; // Store requests that need to be retried once the token is refreshed

// Function to add the request to the queue
const onRefreshed = (accessToken) => {
  refreshSubscribers.forEach((callback) => callback(accessToken)); // Resolve all queued requests with the new token
  refreshSubscribers = []; // Clear the queue
};

// Function to handle adding requests to the queue while refreshing
const addSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

const refreshToken = async () => {
  try {
    const storedRefreshToken = storageService.getFromLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}authentication/mobile-refresh-token/`,
      { refresh_token: storedRefreshToken }
    );
    const newAccessToken = data.data.access_token;
    storageService.setToLocalStorage(STORAGE_KEYS.AUTH_TOKEN, newAccessToken);
    onRefreshed(newAccessToken); // Notify all queued requests with the new token
    return newAccessToken;
  } catch (error) {
  storageService.removeFromLocalStorage(STORAGE_KEYS.AUTH_TOKEN);
  storageService.removeFromLocalStorage(STORAGE_KEYS.USER_DETAILS);
  storageService.removeFromLocalStorage(STORAGE_KEYS.USER_RESET_PASSWORD);
  storageService.removeFromLocalStorage(STORAGE_KEYS.REFRESH_TOKEN);
  if(storageService.getFromLocalStorage(STORAGE_KEYS.REMEMBER_ME)===false){
    storageService.removeFromLocalStorage(
      STORAGE_KEYS.REMEMBER_DEVICE_FOR_30_DAYS
    );
    storageService.removeFromLocalStorage(STORAGE_KEYS.REMEMBER_TOKEN)
  }
  window.location.href = URL.LOGIN;
    throw error;
  }
};

// Request interceptor to add the access token to every request
agent.interceptors.request.use((config) => {
  const token = storageService.getFromLocalStorage(STORAGE_KEYS.AUTH_TOKEN);
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// Response interceptor to handle token expiration (401 errors)
agent.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const SOMETHING_WENT_WRONG = "Something went wrong";
    const { data, status } = error?.response || { data: "", status: "" };

    if (data?.detail) {
      data.message = data?.detail;
    }
    const errorObject = data?.errors;
    let errorMessage = data?.message || error?.message || SOMETHING_WENT_WRONG;
    if (errorObject) {
      if (Object.keys(errorObject)?.length > 0) {
        Object.entries(errorObject).forEach(([_, value]) => {
          if (value?.length > 0) {
            errorMessage = value[0];
          }
        });
      }
    }

    // Check if the error is a 401 (Unauthorized)
    if (status === 401) {
      const originalRequest = error.config;

      if (!originalRequest._retry) {
        originalRequest._retry = true;

        // If a refresh token request is already in progress, queue the request
        if (isRefreshing) {
          return new Promise((resolve) => {
            addSubscriber((accessToken) => {
              originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
              resolve(agent(originalRequest)); // Retry the request with the new token
            });
          });
        }

        // If no refresh token request is in progress, start the process
        isRefreshing = true;
        try {
          // Get new access token by calling the refreshToken function
          const newAccessToken = await refreshToken();
          
          // Update the original request with the new access token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          
          // Retry the original request with the new token
          return agent(originalRequest);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        } finally {
          isRefreshing = false; // Reset the flag once the refresh process is done
        }
      }
    }

    // Handle specific error status codes
    switch (status) {
      case 400:
        toast.error(errorMessage);
        break;
      case 404:
        toast.error(errorMessage);
        break;
      case 401:
        toast.error(errorMessage);
        break;
      case 403:
        toast.error("The Credentials you have entered are not valid");
        break;
      case 500:
        toast.error(errorMessage);
        break;
      default:
        toast.error(errorMessage);
        break;
    }

    return Promise.reject(error?.response?.data || error);
  }
);


const getQueryString = (queryParams) => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return "";
  }
  const filteredParams = Object.entries(queryParams).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  const queryString = Object.keys(filteredParams)
    .map((param) => `${param}=${filteredParams[param]}`)
    .join("&");
  return queryString ? `?${queryString}` : queryString;
};

const multipartHeaders = {
  headers: { "Content-type": "multipart/form-data" },
};

export const apiService = {
  get: (url, params) =>
    agent.get(url + getQueryString(params)).then(responseBody),
  post: (url, body = {}) => agent.post(url, body).then(responseBody),
  put: (url, body = {}) => agent.put(url, body).then(responseBody),
  delete: (url) => agent.delete(url).then(responseBody),
  postForm: (url, data) =>
    agent.post(url, data, multipartHeaders).then(responseBody),
  putForm: (url, data) =>
    agent.put(url, data, multipartHeaders).then(responseBody),
};

export function createFormData(item) {
  const formData = new FormData();
  for (const key in item) {
    formData.append(key, item[key]);
  }
  return formData;
}