import apiURL from "../constants/apiURL";
import { apiService } from "./api.service";

async function getPatientSummary(params) {
  return apiService.get(apiURL.PATIENT_SUMMARY, params);
}

async function getPatientsNameId(params) {
  return apiService.get(apiURL.PATIENT_LIST, params);
}
async function changePatientStatus(id, body) {
  return apiService.put(apiURL.PATIENT_SUMMARY + id + "/", body);
}

async function createPatient(body) {
  return apiService.post(apiURL.PATIENT, body);
}

async function getPatientDetails(id) {
  return apiService.get(apiURL.PATIENT + id + "/");
}
async function deletePatient(id) {
  return apiService.delete(apiURL.PATIENT + id + "/");
}
async function updatePatient(id, body) {
  return apiService.put(apiURL.PATIENT + id + "/", body);
}
async function checkPatientMail(params) {
  return apiService.get(apiURL.PATIENT_EMAIL_CHECK, params);
}
async function assessmentTypeList(params) {
  return apiService.get(apiURL.ASSESSMENT_TYPE_LIST, params);
}

async function getAssessmentSummary(params) {
  return apiService.get(apiURL.ASSESSMENT_SUMMARY, params);
}

async function getAssessmentCSV(params) {
  return apiService.get(apiURL.ASSESSMENT_CSV, params);
}
async function getPatientPDF(id) {
  return apiService.get(`${apiURL.PATIENT_PDF}${id}/`);
}
async function getAssessmentColumn() {
  return apiService.get(apiURL.ASSESSMENT_COLUMN_FILTER);
}
async function createAssessmentColumn(body) {
  return apiService.post(apiURL.ASSESSMENT_COLUMN_FILTER, body);
}
async function updateAssessmentColumn(body) {
  return apiService.put(apiURL.ASSESSMENT_COLUMN_FILTER, body);
}

async function getScoreCSV() {
  return apiService.get(apiURL.SCORE_CSV);
}

async function getPatinetDoctorCount(params) {
    return apiService.get(apiURL.PATIENT_DOCTOR_COUNT, params);
  }
export const patientService = {
  getPatientSummary,
  changePatientStatus,
  createPatient,
  getPatientDetails,
  updatePatient,
  checkPatientMail,
  deletePatient,
  getPatientsNameId,
  assessmentTypeList,
  getAssessmentSummary,
  getAssessmentCSV,
  getPatientPDF,
  getAssessmentColumn,
  createAssessmentColumn,
  updateAssessmentColumn,
  getPatinetDoctorCount
};
