import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/^[a-zA-Z0-9!@#$%^&*()_+=]*$/, "Password contains invalid characters. Only the following special characters are allowed: !@#$%^&*()_+=")
        .matches(/[\!@#$%^&*()_+=]/, "Password must contain at least one special character: !@#$%^&*()_+=")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});