import React from 'react';
import { Modal, Button } from "react-bootstrap";
import "./style.scss";

function ConfirmDeleteModal({
  show,
  onClose,
  onConfirm
}) {
  return (
    <Modal
      className="confirmDelete"
      show={show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-md-12 info'>
            <p>Are you sure?</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose} className='delete-no-button'>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm} className="delete-yes-button">
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModal;
