import "./style.scss";
import { Col, Row } from "react-bootstrap";
import Dashboardfirst from "../../assests/images/dashborad/orga.svg";
import Dashboardsecond from "../../assests/images/dashborad/patient.svg";
import Dashboardthird from "../../assests/images/dashborad/doc.svg";
import { useContext, useState, useEffect } from "react";
import { Store } from "../../store/Store";
import { roles } from "../../constants/common.constants";
import { patientService } from "../../services/patient.service";
import { OrganisationService } from "../../services/Organisation.service";
import {clinicService} from "../../services/clinic.service"
import { setUserDetails } from "../../services/auth.service";
import { Type } from "../../constants/storeAction.constants";
function Dashboard() {
  const [countDetails, setCountDetails] = useState(null);
  const { state, dispatch } = useContext(Store);
  const { userInfo } = state;
  const { user_type } = userInfo;

  useEffect(() => {
    const fetchPatientAndDoctorCount = async () => {
      try {
        const params = {};
        if (user_type === roles.organization) {
          const { data } = await OrganisationService.getOrganisationClinic(userInfo?.id);
          const updatedUserInfo = { ...state.userInfo, name: data.name };
          setUserDetails(updatedUserInfo);
          dispatch({ type: Type.UPDATE_USER_INFO, payload: updatedUserInfo });
          params.organization_id = userInfo?.id;
        } else if (user_type === roles.clinic) {
          params.clinic_id = userInfo?.id;
          const { data } = await clinicService.getClinicDetails(userInfo?.id);
          const updatedUserInfo = { ...state.userInfo, name: data.name };
          setUserDetails(updatedUserInfo);
          dispatch({ type: Type.UPDATE_USER_INFO, payload: updatedUserInfo });
        } else if (user_type === roles.doctor) {
          params.doctor_id = userInfo?.id;
        }
        const response = await patientService.getPatinetDoctorCount(params);
        setCountDetails(response.data);
      } catch (error) {
        console.error("Error fetching patient and doctor count:", error);
      }
    };
    fetchPatientAndDoctorCount();
  }, [userInfo?.id, user_type]);

  const cardPermissions = {
    clinic: [roles.admin, roles.organization],
    organization: [roles.admin],
    patient_id: [roles.doctor, roles.superAdmin],
    assessments:[roles.doctor, roles.superAdmin],
  };

  const clinicCard = () => {
    return (
      cardPermissions.clinic.includes(user_type) && (
        <Col md={4}>
          <div className="inner_div">
            <div>
              <img src={Dashboardfirst} alt="img" />
            </div>
            <div className="ps-3">
              <p>Clinics</p>
              <p>{countDetails?.clinic_count}</p>
            </div>
          </div>
        </Col>
      )
    );
  };

  const organizationCard = () => {
    return (
      cardPermissions.organization.includes(user_type) && (
        <Col md={4}>
          <div className="inner_div">
            <div>
              <img src={Dashboardfirst} alt="img" />
            </div>
            <div className="ps-3">
              <p>Organizations</p>
              <p>{countDetails?.organizations_count}</p>
            </div>
          </div>
        </Col>
      )
    );
  };

  return (
    <div className="dashboard_page">
      <Row>
        {clinicCard()}
        <Col md={4}>
          <div className="inner_div">
            <div>
              <img src={Dashboardsecond} alt="img" />
            </div>
            <div className="ps-3">
              <p>Total Patients</p>
              <p>{countDetails?.patient_count}</p>
            </div>
          </div>
        </Col>
        {!cardPermissions.patient_id.includes(user_type) && (
          <Col md={4}>
            <div className="inner_div">
              <div>
                <img src={Dashboardthird} alt="img" />
              </div>
              <div className="ps-3">
                <p>Total Doctors</p>
                <p>{countDetails?.doctor_count}</p>
              </div>
            </div>
          </Col>
        )}
        {cardPermissions.assessments.includes(user_type) && (
          <Col md={4}>
            <div className="inner_div">
              <div>
                <img src={Dashboardthird} alt="img" />
              </div>
              <div className="ps-3">
                <p>Total Assessments</p>
                <p>{countDetails?.total_scan_count}</p>
              </div>
            </div>
          </Col>
        )}
        {organizationCard()}
      </Row>
    </div>
  );
}

export default Dashboard;
