import CompareComp2 from "../../components/compare2";
import { useParams } from 'react-router-dom';
function Compare2() {

  const { patientId, scanId } = useParams();

  // Now patientId and scanId are available for use
  console.log('Patient ID:', patientId);
  console.log('Scan ID:', scanId);

  return (
      <div className="Patients_section Organization-section AddOrganisationProfile">
        <CompareComp2 
          patientId={patientId}
          scanId={scanId}
        />
      </div>
  );
}
export default Compare2;
