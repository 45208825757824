import { useContext, useEffect, useState } from "react";
import EditOrganisationProfile from "../../organisation/edit/profileDetails";
import EditClinicProfile from "../../clinic/edit/profileDetails";
import { roles } from "../../../constants/common.constants"
import { Store } from "../../../store/Store";
import "./style.scss"
import EditDoctorProfile from "../../doctor/edit/profileDetails";

function EditProfileDetails() {
  const { state, dispatch } = useContext(Store);
  const { userInfo } = state;
  return (
    <>
    <div className="editProfileDetails">
      {userInfo.user_type === roles.organization && (
        <EditOrganisationProfile isProfileEdit={true} />
      )}
      {userInfo.user_type === roles.clinic && <EditClinicProfile isProfileEdit={true}/>}

      {userInfo.user_type === roles.doctor && <EditDoctorProfile isProfileEdit={true}/>}

      </div>
    </>
  );
}
export default EditProfileDetails;