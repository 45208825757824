import { useState } from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "../errorMessage";
import "./style.scss";
import Asterisk from "../asterisk";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function Input({
  touched,
  errors,
  name,
  getFieldProps,
  type,
  placeholder,
  label,
  required = true,
  readOnly = false,
  values,
  // showPasswordCriteria = false,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const passwordCriteria = [
  //   {
  //     validate: (password) => password.length >= 16 && password.length <= 32,
  //     message: "Password must be between 16-32 characters",
  //   },
  //   { validate: (password) => /[a-z]/.test(password), message: "Password must contain at least one lowercase letter" },
  //   { validate: (password) => /[A-Z]/.test(password), message: "Password must contain at least one uppercase letter" },
  //   { validate: (password) => /[0-9]/.test(password), message: "Password must contain at least one number" },
  //   { validate: (password) => /[\W_]/.test(password), message: "Password must contain at least one special character" },
  // ];

  // const validateCriteria = (password) => {
  //   return passwordCriteria.map((criteria) => ({
  //     ...criteria,
  //     isValid: criteria.validate(password),
  //   }));
  // };

  // const criteriaStatus = showPasswordCriteria ? validateCriteria(values.password) : [];

  return (
    <Form.Group className="mb-4 errorClass">
      <Form.Label>
        {label}
        {required && <Asterisk />}
      </Form.Label>
      <div className="password-input-wrapper">
        <Form.Control
          type={showPassword ? "text" : type}
          placeholder={placeholder}
          {...getFieldProps(name)}
          className={`${touched[name] && errors[name] ? "is-invalid-label" : ""} ${
            readOnly ? "not-allowed-cursor" : ""
          }`}
          readOnly={readOnly}
          autoComplete="off"
        />
        {type === "password" && (
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaEyeSlash size={20} color="#0aceec" /> : <FaEye size={20} color="#0aceec" />}
          </span>
        )}
      </div>
      {/* {!showPasswordCriteria && <ErrorMessage errors={errors} touched={touched} name={name} />} */}
      <ErrorMessage errors={errors} touched={touched} name={name} />
      {/* {showPasswordCriteria && (
        <div className="password-criteria">
          {criteriaStatus.map((criteria, index) => (
            <div
              key={index}
              className={criteria.isValid ? "criteria-valid" : "criteria-invalid"}
            >
              {criteria.message}
            </div>
          ))}
        </div>
      )} */}
    </Form.Group>
  );
}

export default Input;
